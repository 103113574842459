import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import { maxScreen } from 'styles/_breakpoints';

const Container = styled.div`
  width: 100%;
  height: fit-content;
`;

const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media ${maxScreen.tablet} {
      flex-direction: column;
    }
  }

  @media ${maxScreen.tablet} {
    flex-direction: column;
  }
`;

const CardBody = styled.div`
  width: 45%;
  height: 100%;
  margin-right: 5rem;
  color: ${({ theme: { colors } }) => colors.white};

  @media ${maxScreen.tablet} {
    width: 100%;
    margin: 0 auto;
  }
`;

const CardTitle = styled.h2`
  width: 100%;
  margin-bottom: 3rem;
  text-align: left;
  font-weight: bold;
`;

const CardDescription = styled.div`
  margin-bottom: 5rem;

  & > p {
    font-size: 1.4rem;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
  }

  & > p:last-of-type {
    margin-bottom: 0;
  }
`;

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: auto;

  & > img {
    object-fit: cover;
    object-position: center;
  }
`;

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        about: {
          id: number;
          title: string;
          card: Card[];
        };
      };
    };
  };
}

interface Card {
  id: number;
  title: string;
  description: string;
  image: {
    src: any;
    alt: string;
  };
}

const AboutCard = ({ datas }: Datas) => {
  const cardData = datas.markdownRemark.frontmatter.about.card.map(
    ({ id, title, description, image }: Card) => (
      <Card key={id}>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <CardDescription>
            <ReactMarkdown children={description} />
          </CardDescription>
        </CardBody>

        <Image image={image.src.childImageSharp.gatsbyImageData} alt={image.alt} />
      </Card>
    ),
  );

  return <Container>{cardData}</Container>;
};

export default AboutCard;
