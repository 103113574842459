import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { AnimationFade } from 'libs/animation';

const Container = styled.section`
  width: 1400px;
  position: fixed;
  bottom: 7rem;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1240px) {
    width: 90%;
  }
`;

const Button = styled.button`
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const Image = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  filter: invert(0%);
`;

const Top = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          top {
            id
            title
            images {
              src {
                publicURL
              }
              alt
            }
          }
        }
      }
    }
  `);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const { title, images } = datas.markdownRemark.frontmatter.top;

  return (
    <Container>
      <Button onClick={() => handleClick()} title={title}>
        <AnimationFade rootMargin='0px 0px' custom={1}>
          <Image src={images.src.publicURL} alt={images.alt} />
        </AnimationFade>
      </Button>
    </Container>
  );
};

export default Top;
