import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';

const Container = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
`;

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 100%;
  color: transparent;

  & > img {
    object-fit: cover;
    object-position: center;
  }
`;

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        hero: {
          id: number;
          images: {
            src: any;
            alt: string;
          };
        };
      };
    };
  };
}

const HeroBG = ({ datas }: Datas) => {
  const { images } = datas.markdownRemark.frontmatter.hero;

  return (
    <Container>
      <Image image={images.src.childImageSharp.gatsbyImageData} alt={images.alt} />
    </Container>
  );
};

export default HeroBG;
