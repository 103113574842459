import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import AboutCard from 'components/About/AboutCard';

import { Wrapper } from 'styles/_mixins';

const Container = styled.section`
  position: relative;
  width: 100%;
  height: fit-content;
  padding: 12.5rem 0 5rem;
`;

const About = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          about {
            id
            slug
            card {
              id
              title
              description
              image {
                src {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      quality: 85
                      formats: [AUTO, WEBP]
                    )
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `);

  const { slug } = datas.markdownRemark.frontmatter.about;

  return (
    <Container id={slug}>
      <Wrapper>
        <AboutCard datas={datas} />
      </Wrapper>
    </Container>
  );
};

export default About;
