import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';

import { maxScreen } from 'styles/_breakpoints';

const GrisdBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  grid-auto-rows: auto;
  gap: 5rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 100%;
  }
`;

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  height: 100%;
  width: 100%;

  @media ${maxScreen.smart} {
    height: 95%;
  }
`;

interface Datas {
  id: number;
  image: {
    src: any;
    alt: string;
  };
}

const WorksCards = ({ datas }: any) => {
  const worksData = datas.markdownRemark.frontmatter.works.card.map(
    ({ id, image }: Datas) => (
      <Image key={id} image={image.src.childImageSharp.gatsbyImageData} alt={image.alt} />
    ),
  );

  return <GrisdBox>{worksData}</GrisdBox>;
};

export default WorksCards;
