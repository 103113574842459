import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import NewsCard from 'components/News/NewsCard';

import { Wrapper } from 'styles/_mixins';

const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 7.5rem 0 5rem;
`;

const Title = styled.h2`
  margin-bottom: 3rem;
`;

const News = () => {
  const datas = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/news/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              id
              slug
              title
              date
              description
              images {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
                  }
                }
                alt
              }
            }
          }
        }
      }
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          news {
            id
            slug
            title
          }
        }
      }
    }
  `);

  const { slug, title } = datas.markdownRemark.frontmatter.news;

  return (
    <Container id={slug}>
      <Wrapper>
        <Title>{title}</Title>
        <NewsCard datas={datas} />
      </Wrapper>
    </Container>
  );
};

export default News;
