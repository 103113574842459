import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';

import { maxScreen } from 'styles/_breakpoints';

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  grid-auto-rows: auto;
  gap: 10rem;
  align-items: center;
  margin-bottom: 5rem;

  &:nth-child(odd) div:first-of-type {
    order: 1;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${maxScreen.tablet} {
    grid-template-columns: 100%;
    gap: 5rem;

    &:nth-child(even) div:first-of-type {
      order: 1;
    }
  }
`;

const CardBody = styled.div`
  width: 100%;
  height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h2`
  margin-bottom: 3rem;
`;

const CardDescription = styled.div`
  position: relative;
  margin-bottom: 5rem;

  & > p {
    font-size: 1.4rem;
    line-height: 2.5rem;
    margin: 0 0 3rem 1.75rem;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 1rem;
      height: 1rem;
      margin-top: 0.85rem;
      border-radius: 50%;
      background: ${({ theme: { colors } }) => colors.white};
    }
  }

  & > p:last-of-type {
    margin-bottom: 0;
  }
`;

const CardImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;

  & > img {
    object-fit: cover;
  }

  @media ${maxScreen.tablet} {
    width: 75%;
    margin: 0 auto;
  }
`;

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        specification: {
          id: number;
          slug: string;
          card: Card[];
        };
      };
    };
  };
}

interface Card {
  id: number;
  title: string;
  description: string;
  image: {
    src: any;
    alt: string;
  };
}

const SpecificationCard = ({ datas }: Datas) => {
  const specificationData = datas.markdownRemark.frontmatter.specification.card.map(
    ({ id, title, description, image }) => (
      <Card key={id}>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <CardDescription>
            <ReactMarkdown>{description}</ReactMarkdown>
          </CardDescription>
        </CardBody>

        <CardImage image={image.src.childImageSharp.gatsbyImageData} alt={image.alt} />
      </Card>
    ),
  );

  return <Box>{specificationData}</Box>;
};

export default SpecificationCard;
