import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Theme from 'styles/_theme';
import GlobalStyle from 'styles/_global';

import Seo from 'components/Seo/Seo';
import Layout from 'components/Layout/Layout';
import Hero from 'components/Hero/Hero';
import About from 'components/About/About';
import Works from 'components/Works/Works';
import Specification from 'components/Specification/Specification';
import News from 'components/News/News';
import Contact from 'components/Contact/Contact';

const IndexPage = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          sites {
            title
            description
          }
        }
      }
    }
  `);

  const { title, description } = datas.markdownRemark.frontmatter.sites;

  return (
    <Theme>
      <GlobalStyle />
      <Seo title={title} description={description} />
      <Layout>
        <Hero />
        <About />
        <Works />
        <Specification />
        <News />
        <Contact />
      </Layout>
    </Theme>
  );
};

export default IndexPage;
