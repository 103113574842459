import styled from 'styled-components';

import { Props } from 'types/types';

import { maxScreen } from 'styles/_breakpoints';

export const Form = styled.form`
  width: 100%;
`;

export const FormFlex = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media ${maxScreen.tablet} {
    flex-direction: column;
  }
`;

export const FormBox = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${maxScreen.tablet} {
    width: 100%;
  }

  select {
    cursor: pointer;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const FormLabel = styled.label`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: ${({ theme: { colors } }) => colors.white};
`;

export const FormInput = styled.input<Props>`
  color: ${({ theme: { colors } }) => colors.gray};
  background: transparent;
  font-size: 1.4rem;
  font-family: inherit;
  padding: 1rem 0.5rem;
  border: 0.1rem solid ${({ theme: { colors } }) => colors.darkgray};

  &:focus {
    outline: none;
  }
`;

export const FormSelect = styled.select<Props>`
  color: ${({ theme: { colors } }) => colors.darkgray};
  background: ${({ theme: { colors } }) => colors.gray};
  font-size: 1.3rem;
  font-family: inherit;
  padding: 1rem 0.5rem;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const FormOption = styled.option<Props>`
  color: ${({ theme: { colors } }) => colors.darkgray};
  background: ${({ theme: { colors } }) => colors.gray};
  font-size: 1.3rem;
  font-family: inherit;

  &:hover {
    background: ${({ theme: { colors } }) => colors.red};
  }
`;

export const FormMessage = styled.textarea<Props>`
  height: 23rem;
  resize: vertical;
  color: ${({ theme: { colors } }) => colors.gray};
  background: transparent;
  font-size: 1.4rem;
  font-family: inherit;
  padding: 1rem 0.5rem;
  border: 0.1rem solid ${({ theme: { colors } }) => colors.darkgray};

  &:focus {
    outline: none;
  }
`;

export const Message = styled.p`
  margin-top: 0.75rem;
  color: ${({ theme: { colors } }) => colors.red};
`;

export const FormButton = styled.button`
  margin-top: 5rem;
  font-size: clamp(1.3rem, 1vw, 1.4rem);
  padding: 0.85rem 1rem;
  border: none;
  text-transform: uppercase;
  font-family: inherit;
  background: ${({ theme: { colors } }) => colors.mint};
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
  transition: all 0.3s ease-in;

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${({ theme: { colors } }) => colors.darkmint};
  }
`;

export const CheckBox = styled.section`
  position: relative;
  width: fit-content;
  margin-bottom: 0;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const CheckBoxSpan = styled.span`
  display: block;
`;

export const CheckBoxInput = styled.input<Props>`
  width: fit-content;
  height: 25px;
  width: 25px;
  margin-right: 1rem;
  appearance: none;
  background: transparent;
  border: 0.1rem solid ${({ theme: { colors } }) => colors.darkgray};
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;

  &:checked {
    background: transparent;
    border: 0.1rem solid ${({ theme: { colors } }) => colors.darkgray};
  }

  &:active {
    border: 2px solid ${({ theme: { colors } }) => colors.darkgray};
  }
`;

export const CheckBoxLabel = styled.label`
  display: flex;
  margin-bottom: 0;
  font-size: 1.5rem;
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;

  & > ${CheckBoxInput}:checked + ${CheckBoxSpan}::before {
    content: '✔';
    color: ${({ theme: { colors } }) => colors.green};
    position: absolute;
    left: 0.7rem;
    top: 0;
    font-size: 1.6rem;
  }
`;
