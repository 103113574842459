import React from 'react';
import styled from 'styled-components';

import { maxScreen } from 'styles/_breakpoints';

const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 3rem 0 7rem;
`;

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28%, 1fr));
  grid-auto-rows: auto;
  gap: 5rem;
`;

const Card = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  color: ${({ theme: { colors } }) => colors.gray};
`;

const CardImage = styled.img`
  width: 2rem;
  height: 2rem;
  filter: invert(100%);
`;

const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const CardText = styled.p`
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        contact: {
          id: number;
          title: string;
          card: Card[];
        };
      };
    };
  };
}

interface Card {
  id: number;
  textOne: string;
  textTwo: string;
  icon: {
    src: any;
    alt: string;
  };
}

const ContactInfo = ({ datas }: Datas) => {
  const contactData = datas.markdownRemark.frontmatter.contact.card.map(
    ({ id, textOne, textTwo, icon }) => (
      <Card key={id}>
        <CardImage src={icon.src.publicURL} alt={icon.alt} />
        <CardBox>
          <CardText>{textOne}</CardText>
          <CardText>{textTwo}</CardText>
        </CardBox>
      </Card>
    ),
  );

  return (
    <Container>
      <GridBox>{contactData}</GridBox>
    </Container>
  );
};

export default ContactInfo;
