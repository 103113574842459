import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import HeroBG from 'components/Hero/HeroBG';
import HeroContent from 'components/Hero/HeroContent';

const Container = styled.section`
  width: 100%;
  height: 100vh;
`;

const Hero = () => {
  const datas = useStaticQuery(
    graphql`
      query {
        markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
          frontmatter {
            hero {
              id
              slug
              title
              description
              images {
                src {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      quality: 100
                      formats: [AUTO, WEBP]
                    )
                  }
                }
                alt
              }
              icons {
                src {
                  name
                  publicURL
                }
                alt
              }
              linkTo
              linkText
            }
          }
        }
      }
    `,
  );

  const { slug } = datas.markdownRemark.frontmatter.hero;

  return (
    <Container id={slug}>
      <HeroBG datas={datas} />
      <HeroContent datas={datas} />
    </Container>
  );
};

export default Hero;
