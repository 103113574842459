import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import ContactForm from 'components/Contact/ContactForm';
import ContactInfo from 'components//Contact/ContactInfo';
import Top from 'components/Top/Top';

import { Wrapper } from 'styles/_mixins';

const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 7.5rem 0 12.5rem;
`;

const Title = styled.h2`
  margin-bottom: 3rem;
`;

const Contact = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          contact {
            id
            slug
            title
            card {
              id
              textOne
              textTwo
              icon {
                src {
                  name
                  publicURL
                }
                alt
              }
            }
          }
        }
      }
    }
  `);

  const [ref, inView] = useInView({
    rootMargin: '-100px 0px',
  });

  const { slug, title } = datas.markdownRemark.frontmatter.contact;

  return (
    <Container id={slug} ref={ref}>
      <Wrapper height='true'>
        <Title>{title}</Title>
        <ContactInfo datas={datas} />
        <ContactForm />
        {inView && <Top />}
      </Wrapper>
    </Container>
  );
};

export default Contact;
