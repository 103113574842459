import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { maxScreen } from 'styles/_breakpoints';
import {
  Message,
  Form,
  FormFlex,
  FormBox,
  FormGroup,
  FormLabel,
  FormInput,
  FormMessage,
  FormButton,
  CheckBox,
  CheckBoxInput,
  CheckBoxLabel,
  CheckBoxSpan,
} from './_contactform';

const Container = styled.section`
  width: 100%;
  height: fit-content;

  @media ${maxScreen.tablet} {
    width: 100%;
  }
`;

interface ContactProps {
  name: string;
  email: string;
  subject: string;
  message: string;
  checkbox: boolean;
}

const ContactForm = () => {
  const { register, handleSubmit, errors, formState } = useForm<ContactProps>({
    mode: 'onChange',
  });
  const [values, setValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    checkbox: false,
  });

  const { name, email, subject, message, checkbox } = values;

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setValues({ ...values, [e.target.name]: e.target.value, checkbox: true });
  };

  const onSubmit = handleSubmit((data, e: any) => {
    e.preventDefault();

    const form = e.target;

    axios({
      method: 'post',
      url: 'https://formspree.io/f/mwkwvdvr',
      data: new FormData(form),
    })
      .then((response) => {
        if (checkbox) e.target.reset();

        toast.dark(`Thanks for reaching out. I'll get back to you soon.`);
        setValues({
          ...values,
          name: '',
          email: '',
          subject: '',
          message: '',
          checkbox: false,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.dark('Oops, something went wrong. The form could not be submitted.');
        setValues({ ...values, checkbox: false });
      });
  });

  return (
    <Container>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Form name='contact' onSubmit={onSubmit}>
        <FormFlex>
          <FormBox>
            <FormGroup>
              <FormLabel htmlFor='name' className='form-label'>
                Name
              </FormLabel>
              <FormInput
                ref={register({
                  required: true,
                  minLength: 3,
                  maxLength: 20,
                  pattern: /^[A-Za-z]+$/i,
                })}
                id='name'
                type='text'
                name='name'
                placeholder='Name'
                value={name}
                onChange={handleChange}
              />
              {errors.name && <Message>Please enter a valid name.</Message>}
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor='email' className='form-label'>
                Email
              </FormLabel>
              <FormInput
                ref={register({
                  required: true,
                  validate: (input) => isEmail(input),
                  minLength: 6,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i,
                })}
                id='email'
                type='email'
                name='email'
                placeholder='Email'
                value={email}
                onChange={handleChange}
              />
              {errors.email && <Message>Please enter a valid email.</Message>}
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor='subject' className='form-label'>
                Subject
              </FormLabel>
              <FormInput
                ref={register({
                  required: true,
                  minLength: 3,
                  maxLength: 20,
                  pattern: /^[A-Za-z]+$/i,
                })}
                id='subject'
                type='text'
                name='subject'
                placeholder='Text'
                value={subject}
                onChange={handleChange}
              />
              {errors.subject && <Message>Please enter a subject.</Message>}
            </FormGroup>
          </FormBox>

          <FormBox>
            <FormGroup>
              <FormLabel htmlFor='message' className='form-label'>
                Message
              </FormLabel>
              <FormMessage
                ref={register({
                  required: true,
                  maxLength: 300,
                })}
                id='message'
                name='message'
                placeholder='Message'
                value={message}
                onChange={handleChange}
              ></FormMessage>
              {errors.message && <Message>Please enter a message.</Message>}
            </FormGroup>
          </FormBox>
        </FormFlex>

        <CheckBox>
          <CheckBoxLabel htmlFor='checkbox'>
            <CheckBoxInput
              ref={register({ required: true })}
              type='checkbox'
              name='checkbox'
              id='checkbox'
            />
            I'm a human.
            <CheckBoxSpan onChange={handleChange}></CheckBoxSpan>
          </CheckBoxLabel>

          {errors.checkbox && <Message>Please check the checkbox.</Message>}
        </CheckBox>

        <FormButton type='submit' disabled={formState.isSubmitting}>
          Send
        </FormButton>
      </Form>
    </Container>
  );
};

export default ContactForm;
