import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import WorksCard from 'components/Works/WorksCard';

import { Wrapper } from 'styles/_mixins';

const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 7.5rem 0 5rem;
  background: ${({ theme: { colors } }) => colors.offwhite};
  clip-path: polygon(0 6%, 100% 0, 100% 94%, 0% 100%);
`;

const Title = styled.h2`
  width: 100%;
  margin-bottom: 3rem;
  text-align: center;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.black};
`;

const Works = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          works {
            id
            slug
            title
            card {
              id
              image {
                src {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      quality: 85
                      formats: [AUTO, WEBP]
                    )
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `);

  const { slug, title } = datas.markdownRemark.frontmatter.works;

  return (
    <Container id={slug}>
      <Wrapper>
        <Title>{title}</Title>
        <WorksCard datas={datas} />
      </Wrapper>
    </Container>
  );
};

export default Works;
