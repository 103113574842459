import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';

import { maxScreen } from 'styles/_breakpoints';

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-auto-rows: auto;
  gap: 3rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 100%;
  }
`;

const Card = styled.div`
  width: 100%;
  height: fit-content;

  @media ${maxScreen.tablet} {
    margin-bottom: 3rem;
  }
`;

const CardImageLink = styled(Link)`
  width: 100%;
  height: 100%;
`;

const CardImage = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 30rem;

  @media ${maxScreen.tablet} {
    height: 40rem;
  }
`;

const CardBody = styled.div`
  width: 100%;
  height: 23rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;

  @media ${maxScreen.tablet} {
    height: 20rem;
  }
`;

const CardBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const CardTitle = styled.h3`
  margin-bottom: 0;
`;

const CardDate = styled.p`
  margin-bottom: 0;
  font-size: 1.3rem;
`;

const CardDescription = styled.p`
  flex-grow: 1;
  margin-bottom: 2rem;
  line-height: 2.25rem;
`;

const CardLink = styled(Link)`
  display: flex;
  justify-content: flex-end;
  color: ${({ theme: { colors } }) => colors.mint};
  transition: all 0.3s ease-in;

  &:hover {
    color: ${({ theme: { colors } }) => colors.darkmint};
  }
`;

interface Datas {
  node: {
    frontmatter: Blog;
  };
}

interface Blog {
  id: number;
  slug: string;
  title: string;
  date: string;
  description: string;
  images: {
    src: any;
    alt: string;
  };
}

const NewsCard = ({ datas }: any) => {
  const blogDatas = datas.allMarkdownRemark.edges.map(({ node }: Datas) => (
    <Card key={node.frontmatter.id}>
      <CardImageLink to={`/post/${node.frontmatter.slug}`}>
        <CardImage
          image={node.frontmatter.images.src.childImageSharp.gatsbyImageData}
          alt={node.frontmatter.images.alt}
        />
      </CardImageLink>
      <CardBody>
        <CardBox>
          <CardTitle>{node.frontmatter.title}</CardTitle>
          <CardDate>{node.frontmatter.date}</CardDate>
        </CardBox>
        <CardDescription>{node.frontmatter.description.slice(0, 150)}...</CardDescription>
        <CardLink to={`/post/${node.frontmatter.slug}`}>{`Read Post...`}</CardLink>
      </CardBody>
    </Card>
  ));

  return <CardGrid>{blogDatas.slice(0, 3)}</CardGrid>;
};

export default NewsCard;
