import React from 'react';
import styled from 'styled-components';

import AnchorLink from 'components/Button/AnchorLink';

const ScrollLink = styled(AnchorLink)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const Icons = styled.img`
  width: 3rem;
  height: 3rem;
  filter: ${({ theme: { filter } }) => filter.mint};
  transform: translateY(0);
  transition: all 0.3s ease-in;

  &:hover {
    filter: ${({ theme: { filter } }) => filter.darkmint};
    transform: translateY(25%);
  }
`;

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        hero: {
          id: number;
          icons: {
            src: {
              name: string;
              publicURL: string;
            };
            alt: string;
          };
          linkTo: string;
        };
      };
    };
  };
}

const Down = ({ datas }: Datas) => {
  const { icons, linkTo } = datas.markdownRemark.frontmatter.hero;
  return (
    <ScrollLink to={linkTo}>
      <Icons src={icons.src.publicURL} alt={icons.alt} />
    </ScrollLink>
  );
};

export default Down;
