import React from 'react';
import styled from 'styled-components';

import Down from 'components/Down/Down';
import Social from 'components/Social/Social';

import { Wrapper } from 'styles/_mixins';
import { maxScreen } from 'styles/_breakpoints';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 37%;
    height: 100%;
    background: ${({ theme: { colors } }) => colors.opblack};

    @media ${maxScreen.tablet} {
      width: 60%;
    }
  }

  & ${Wrapper} {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: auto;
    gap: 5rem;

    @media ${maxScreen.tablet} {
      grid-template-columns: 2fr 1fr;
    }
  }
`;

const Content = styled.div`
  position: relative;
  bottom: 2.5rem;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${({ theme: { colors } }) => colors.white};
`;

const Title = styled.h1`
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
  width: 90%;
  margin-bottom: 10rem;
  line-height: 2.25rem;
  font-size: 2rem !important;
`;

const SocialBox = styled.div`
  width: 100%;
  height: calc(100% - 3rem);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        hero: {
          id: number;
          title: string;
          description: string;
          images: {
            src: any;
            alt: string;
          };
          icons: {
            src: {
              name: string;
              publicURL: string;
            };
            alt: string;
          };
          linkTo: string;
        };
      };
    };
  };
}

const HeroContent = ({ datas }: Datas) => {
  const { title, description } = datas.markdownRemark.frontmatter.hero;

  return (
    <Container>
      <Wrapper height='false'>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Down datas={datas} />
        </Content>
        <SocialBox>
          <Social />
        </SocialBox>
      </Wrapper>
    </Container>
  );
};

export default HeroContent;
